import { Icon } from '@liveconnect/icons'
import { FC } from 'react'

import './styles.scss'

type BackButtonProps = {
  onClick: () => void
  label: string
}

const BackButton: FC<BackButtonProps> = ({ onClick, label }) => {
  return (
    <div className="contLink" aria-hidden="true" onClick={onClick}>
      <Icon name="chevron_left" />
      <p>{label}</p>
    </div>
  )
}

export default BackButton
