import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup
    .object()
    .shape({
      message: yup
        .string()
        .label(t('post.comment.message'))
        .max(650, ({ label }) =>
          t('validations.maxLength', { label, maxLength: 650 })
        ),
    })
    .required()
}
