import { AnyObjectSchema, ValidationError } from 'yup'
import {
  IMAGE_KEY,
  MAX_CROP_VIEW_HEIGHT_SIZE,
  MAX_CROP_VIEW_WIDTH_SIZE,
} from './constants'

export const getFileType = (extensionFile: string) =>
  extensionFile.split('/')[0]

export function getDimensions(originalWidth: number, originalHeight: number) {
  const aspectRatio = originalWidth / originalHeight

  let width =
    originalWidth > MAX_CROP_VIEW_WIDTH_SIZE
      ? MAX_CROP_VIEW_WIDTH_SIZE
      : originalWidth
  let height =
    originalHeight > MAX_CROP_VIEW_HEIGHT_SIZE
      ? MAX_CROP_VIEW_HEIGHT_SIZE
      : originalHeight

  if (
    originalWidth > MAX_CROP_VIEW_WIDTH_SIZE &&
    originalHeight < MAX_CROP_VIEW_HEIGHT_SIZE
  ) {
    width = MAX_CROP_VIEW_WIDTH_SIZE
    height = MAX_CROP_VIEW_WIDTH_SIZE / aspectRatio
  }

  if (
    originalHeight > MAX_CROP_VIEW_HEIGHT_SIZE &&
    originalWidth < MAX_CROP_VIEW_WIDTH_SIZE
  ) {
    height = MAX_CROP_VIEW_HEIGHT_SIZE
    width = MAX_CROP_VIEW_HEIGHT_SIZE * aspectRatio
  }

  return {
    height,
    width,
  }
}

export function getDimensionPonderations(
  originalWidth: number,
  originalHeight: number,
  viewWidth: number,
  viewHeight: number
) {
  let widthPonderation = originalWidth / MAX_CROP_VIEW_WIDTH_SIZE
  let heightPonderation = originalHeight / MAX_CROP_VIEW_HEIGHT_SIZE

  if (viewHeight < originalHeight) {
    heightPonderation = originalHeight / viewHeight
  }

  if (viewWidth < originalWidth) {
    widthPonderation = originalWidth / viewWidth
  }

  return {
    width: widthPonderation,
    height: heightPonderation,
  }
}

export async function validateChange(
  files: File[],
  imageSchema: AnyObjectSchema,
  videoSchema: AnyObjectSchema,
  onError: (error: ValidationError) => void
): Promise<boolean> {
  try {
    if (files[0].type.startsWith(IMAGE_KEY)) {
      await imageSchema.validate({ files })
    } else {
      await videoSchema.validate({ files })
    }
    return true
  } catch (reason) {
    if (reason instanceof ValidationError) onError(reason)
    return false
  }
}
