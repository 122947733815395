import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TenantConfig, TenantConfigState } from './types'

const initialState: TenantConfigState = {
  tenantConfig: null,
}

export const slice = createSlice({
  name: 'tenantConfig',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<TenantConfig>) => {
      state.tenantConfig = action.payload
    },
  },
})

export const { setConfig } = slice.actions

export default slice.reducer
