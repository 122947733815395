import { FC, useEffect } from 'react'

import useAuth from '../../../core/auth/useAuth'

const SigninSilentRenew: FC = () => {
  const { signinSilentCallback } = useAuth()

  useEffect(() => {
    async function handleRenewToken() {
      try {
        await signinSilentCallback()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error on signinSilentCallback', error)
      }
    }

    handleRenewToken()
  }, [signinSilentCallback])

  return null
}

export default SigninSilentRenew
