import { useEffect, FC } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import useAuth from '../../../core/auth/useAuth'

const Logout: FC = () => {
  const { user, logout } = useAuth()
  const [searchParams] = useSearchParams()
  const { tenantId } = useParams()

  useEffect(() => {
    const tenant =
      user?.profile.tenant_id ??
      tenantId ??
      window.location.pathname.split('/').at(2)
    let redirectUrl: string | undefined
    if (searchParams.get('post_logout_path')) {
      redirectUrl = searchParams.get('post_logout_path') as string
    } else if (tenant) {
      redirectUrl = `/t/${tenant}`
    }
    logout(redirectUrl)
  }, [logout, searchParams, tenantId])
  return null
}

export default Logout
