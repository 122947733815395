import { useCallback } from 'react'
import { Transition } from 'history'
import { useTranslation } from 'react-i18next'

import useUi from '../../core/ui/useUi'
import { useBlocker } from './useBlock'

export function useBlockRouteChangeWithDialog(when = true) {
  const { t } = useTranslation()
  const { showConfirmation } = useUi()

  const showDialog = useCallback(() => {
    return new Promise((resolve) => {
      showConfirmation({
        title: t('modal.cancel.generic.title'),
        subtitle: t('modal.cancel.generic.subtitle'),
        text: t('modal.cancel.generic.text'),
        confirmText: t('common.yes'),
        cancelText: t('common.no'),
        iconName: 'report_problem',
        onConfirm: () => {
          resolve(true)
        },
        onCancel: () => {
          resolve(false)
        },
      })
    })
  }, [showConfirmation, t])

  const blocker = useCallback(async (tx: Transition) => {
    const isConfirmed = await showDialog()
    if (isConfirmed) {
      tx.retry()
    }
  }, [])

  useBlocker(blocker, when)

  return { block: showDialog }
}
