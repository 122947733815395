import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import { Localization, LocalizationsState } from './types'

const initialState: LocalizationsState = {
  localizations: [],
}

export const slice = createSlice({
  name: 'localizations',
  initialState,
  reducers: {
    setLocalizations: (state, action: PayloadAction<Localization[]>) => {
      state.localizations = action.payload
    },
  },
})

export const { setLocalizations } = slice.actions

export const selectLocalizations = (state: RootState) => state.localizations

export default slice.reducer
