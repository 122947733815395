import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import { Icon } from '@liveconnect/icons'

import {
  SnackbarContentType,
  SnackbarType,
} from '../../utils/notifications/types'

import './index.scss'

const Snackbar: FC = () => {
  return <ToastContainer limit={1} />
}

const snackbarContentMapper: Record<SnackbarType, SnackbarContentType> = {
  error: { icon: 'info_outline', prefix: '' },
  warning: { icon: 'info_outline', prefix: '' },
  success: { icon: 'check_circle_outline', prefix: '' },
}

const SnackbarContent: FC<{ type: SnackbarType; message: string }> = ({
  type,
  message,
}) => {
  const { t } = useTranslation()
  const content = snackbarContentMapper[type]
  const formattedPrefix = t(content.prefix)

  return (
    <div className="snackbar">
      {content.icon && (
        <Icon name={content.icon} className="snackbar__icon mr-2" />
      )}
      <span className="snackbar__text">
        {formattedPrefix && <span className="mr-1">{formattedPrefix}</span>}
        {message}
      </span>
    </div>
  )
}

export { SnackbarContent }
export default Snackbar
