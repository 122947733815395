import { scrollableId } from '../containers/Layout'

const useScroll = () => {
  const scrollTop = () => {
    const container = document.querySelector(`#${scrollableId}`)

    if (!container || !container?.scrollTop) {
      return
    }

    container.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return {
    scrollTop,
  }
}

export default useScroll
