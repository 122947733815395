import {
  getDateWithTimezoneDiff,
  isNullOrEmpty,
} from '@liveconnect/communities-ui'

export function calculateShowMore(
  textParent: HTMLDivElement | null,
  textContainer: HTMLDivElement | null,
  message: string
): boolean {
  if (textContainer === null) return false
  if (textParent === null) return false
  if (isNullOrEmpty(message)) return false
  const firstWord = message.split(' ')[0]
  if (firstWord.length > 80) return true

  const containerStyles = window.getComputedStyle(textContainer)
  const containerHeight = parseInt(containerStyles.height.replace('px', ''))
  const textStyles = window.getComputedStyle(textParent)
  const textHeight = parseInt(textStyles.height.replace('px', ''))

  return textHeight < containerHeight
}

export function getTextWithEllipsis(text: string): string {
  if (isNullOrEmpty(text)) return ''
  const firstWord = text.split(' ')[0]
  if (firstWord.length > 80) return `${text.substring(0, 77)}...`
  return `${text.substring(0, 77)}...`
}

export enum EllapsedPeriod {
  NONE,
  MINUTES,
  HOURS,
  DAYS,
}
export interface RemainingTime {
  periodType: EllapsedPeriod
  period?: number | Date
}

export const showRemainingTime = (utcDate: Date): RemainingTime => {
  const date = getDateWithTimezoneDiff(utcDate)
  const ellapsedSeconds =
    (new Date().getTime() - new Date(date).getTime()) / 1000
  if (ellapsedSeconds < 60) return { periodType: EllapsedPeriod.NONE }
  const ellapsedMinutes = Math.floor(ellapsedSeconds / 60)
  if (ellapsedMinutes < 60) {
    return { periodType: EllapsedPeriod.MINUTES, period: ellapsedMinutes }
  }
  const ellapsedHours = Math.floor(ellapsedMinutes / 60)
  if (ellapsedHours < 24) {
    return { periodType: EllapsedPeriod.HOURS, period: ellapsedHours }
  }

  return {
    periodType: EllapsedPeriod.DAYS,
    period: getDateWithTimezoneDiff(date),
  }
}
