import * as yup from 'yup'
import { TFunction } from 'react-i18next'

const re = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    video: yup
      .string()
      .label(t('post.videoUpload.label'))
      .required(({ label }) => t('validations.required', { label }))
      .matches(re, t('validations.url')),
  })
}
