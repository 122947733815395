import { UserManager } from 'oidc-client'

import { buildOidcSettings } from './utils'
import { Settings } from '../settings/types'

export default class AppUserManager {
  private static instance: AppUserManager
  public user: UserManager

  private constructor(settings: Settings) {
    const oidcSettings = buildOidcSettings(settings)
    this.user = new UserManager(oidcSettings)
  }

  public static getInstance(settings: Settings | null): AppUserManager {
    if (!AppUserManager.instance && settings) {
      AppUserManager.instance = new AppUserManager(settings)
    }

    return AppUserManager.instance
  }

  public static isInstanced(): boolean {
    return !!AppUserManager.instance
  }
}
