import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Me, MeState } from './types'

const initialState: MeState = {
  detail: null,
}

export const slice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setDetail: (state, action: PayloadAction<Me>) => {
      state.detail = action.payload
    },
  },
})

export const { setDetail } = slice.actions

export default slice.reducer
