import { format, Locale } from 'date-fns'
import { enGB, es } from 'date-fns/esm/locale'

import dates from './dates.json'
import { DateType } from './types'

type IsoCode = 'es-ES' | 'en-GB'
type DateFormat = {
  [key in DateType]: string
}
type DateTypes = {
  [key in IsoCode]: DateFormat
}
const useCulture = () => {
  const isoCode: IsoCode = 'es-ES'

  const formatDate = (value: Date, dateFormat: DateType): string => {
    const dateTypes = dates as DateTypes
    return format(value, dateTypes[isoCode][dateFormat], {
      locale: getLocaleFromIsoCode(isoCode),
    })
  }

  const formatNumber = (value: number): string =>
    new Intl.NumberFormat(isoCode).format(value)

  const getLocaleFromIsoCode = (isoCode: IsoCode): Locale => {
    switch (isoCode) {
      case 'es-ES':
        return es
      case 'en-GB':
      default:
        return enGB
    }
  }

  return {
    formatDate,
    formatNumber,
  }
}

export default useCulture
