import { IconName } from '@liveconnect/icons'

export const enum SnackbarOptions {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export type SnackbarType = `${SnackbarOptions}`

export type SnackbarContentType = {
  icon: IconName
  prefix: string
}

export type useNotificationsResult = {
  error: (m: string) => void
  warning: (m: string) => void
  success: (m: string) => void
}
