import { FC, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import classNames from 'classnames'

import { Icon } from '@liveconnect/icons'
import {
  CheckboxControl,
  TextControl,
  ButtonAsync,
} from '@liveconnect/components'

import useUi from '../../core/ui/useUi'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const ConfirmationModal: FC = () => {
  const { t } = useTranslation()
  const {
    confirmation: {
      isVisible,
      title,
      subtitle,
      text,
      cancelText,
      confirmText,
      iconName = 'report_problem',
      className,
      confirmActionOptions,
      onCancel,
      onConfirm,
    },
    hideConfirmation,
  } = useUi()
  const [checkboxes, setCheckboxes] = useState<
    { value: boolean; required: boolean }[]
  >([])
  const [word, setWord] = useState('')

  const handleChangeCheckbox = (index: number, value: boolean) => {
    setCheckboxes((prev) => {
      const newCheckboxes = [...prev]
      newCheckboxes[index].value = value
      return newCheckboxes
    })
  }

  const handleCancel = () => {
    onCancel && onCancel()
    hideConfirmation()
  }

  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm(checkboxes.map((checkbox) => checkbox.value))
    }
    hideConfirmation()
  }

  const isConfirmDisabled = useMemo(() => {
    if (confirmActionOptions) {
      let anyCheckboxFalse = false
      if (
        confirmActionOptions.checkboxes?.some((checkbox) => checkbox.required)
      ) {
        anyCheckboxFalse = checkboxes.some(
          (checkbox) => checkbox.required && !checkbox.value
        )
      }
      const wordIsIncorrect = confirmActionOptions.word !== word
      return anyCheckboxFalse || wordIsIncorrect
    }
    return false
  }, [confirmActionOptions, checkboxes, word])

  useEffect(() => {
    if (confirmActionOptions?.checkboxes) {
      setCheckboxes(
        confirmActionOptions.checkboxes.map((checkbox) => ({
          value: false,
          required: checkbox.required,
        }))
      )
    }
  }, [confirmActionOptions])

  useEffect(() => {
    setWord('')
  }, [isVisible])

  return (
    <Modal
      show={isVisible}
      onHide={hideConfirmation}
      className={classNames('ConfirmationModal', className)}
      backdrop="static"
      backdropClassName="ConfirmationBackdrop"
    >
      <Modal.Header className="justify-content-around">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          {iconName && (
            <div className={`text-center ${iconName}`}>
              <Icon name={iconName} />
            </div>
          )}
          <div className="text-center content">
            {subtitle && <h6>{subtitle}</h6>}
            {typeof text === 'string' ? (
              <p className="ConfirmationModal__text">{text}</p>
            ) : (
              text
            )}
          </div>

          {confirmActionOptions && (
            <div className="confirm-action">
              {confirmActionOptions.checkboxes && checkboxes && (
                <div className="confirm-action__checkboxes">
                  {checkboxes.map((option, index) => (
                    <CheckboxControl
                      key={index}
                      label={confirmActionOptions!.checkboxes![index].text}
                      name="checkbox"
                      value={option.value}
                      onChange={(v) => {
                        handleChangeCheckbox(index, v)
                      }}
                    />
                  ))}
                </div>
              )}
              <div className="confirm-action__word">
                <p
                  dangerouslySetInnerHTML={{
                    __html: confirmActionOptions.confirmText,
                  }}
                />
                <TextControl
                  label="word"
                  hiddenLabel
                  placeholder={t('confirmationModal.word.placeholder')}
                  type="text"
                  name="word"
                  value={word}
                  onChange={(value) => {
                    setWord(value)
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {cancelText && (
          <button className="btn btn-outline-primary" onClick={handleCancel}>
            {cancelText || t('common.cancel')}
          </button>
        )}
        <ButtonAsync
          disabled={isConfirmDisabled}
          className={
            !cancelText ? 'btn btn-primary btn-only' : 'btn btn-primary'
          }
          onClick={handleConfirm}
        >
          {confirmText || t('common.accept')}
        </ButtonAsync>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
