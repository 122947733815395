import { FC } from 'react'
import { ChannelCardProps } from './types'

import './styles.scss'

const ChannelCard: FC<ChannelCardProps> = ({ title }) => {
  return (
    <div className="channelCard">
      <p>{title}</p>
    </div>
  )
}

export default ChannelCard
