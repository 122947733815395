import classNames from 'classnames'
import Cropper from 'react-easy-crop'
import { Modal } from 'react-bootstrap'
// eslint-disable-next-line import/no-unresolved
import { Area } from 'react-easy-crop/types'
import { useTranslation } from 'react-i18next'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { Icon } from '@liveconnect/icons'
import { isNullOrEmpty } from '@liveconnect/communities-ui'

import { getDimensions } from '../upload.utils'
import { getCroppedBlob } from './crop.utils'
import { Crop, CropDimensions } from './types'
import {
  MAX_CROP_VIEW_HEIGHT_SIZE,
  MAX_CROP_VIEW_WIDTH_SIZE,
} from '../constants'

import './index.scss'

const MIN_CROP_SCALE = 0.5
const MAX_CROP_SCALE = 3
const MIN_CROP_ROTATION = -180
const MAX_CROP_ROTATION = 180

export interface CropImageProps {
  src: string
  width?: number
  height?: number
  onCancelCrop: () => void
  onCompleteCrop: (croppedBlob: Blob) => void
}

const InternalCropImage: FC<CropImageProps> = ({
  src,
  onCancelCrop,
  onCompleteCrop,
  width = MAX_CROP_VIEW_WIDTH_SIZE,
  height = MAX_CROP_VIEW_HEIGHT_SIZE,
}) => {
  const { t } = useTranslation()
  const [scale, setScale] = useState<number>(1)
  const [show, setShow] = useState<boolean>(false)
  const [rotation, setRotation] = useState<number>(0)
  const [isScaling, setIsScale] = useState<boolean>(false)
  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 })
  const [isRotating, setIsRotating] = useState<boolean>(false)
  const [aspectRatio, setAspectRatio] = useState<number | undefined>()
  const [croppedAreaPixels, setCrooppedAreaPixels] = useState<
    Area | undefined
  >()
  const [dimensions, setDimensions] = useState<CropDimensions | undefined>()

  useEffect(() => {
    setShow(!isNullOrEmpty(src))
  }, [src])

  useEffect(() => {
    const viewDimensions = getDimensions(width, height)
    setDimensions(viewDimensions)
    setAspectRatio(viewDimensions.width / viewDimensions.height)
  }, [width, height])

  const handleToogleIsRotating = async () => {
    setIsRotating(!isRotating)
    setIsScale(false)
  }
  const handleToogleIsScaling = async () => {
    setIsScale(!isScaling)
    setIsRotating(false)
  }

  const handleCropComplete = useCallback((_area: Area, areaPixels: Area) => {
    setCrooppedAreaPixels(areaPixels)
  }, [])

  const resetCrop = () => {
    setShow(false)
    setRotation(0)
    setScale(1)
    setIsScale(false)
    setIsRotating(false)
    setCrooppedAreaPixels(undefined)
  }

  const handleClose = (): void => {
    resetCrop()
    onCancelCrop()
  }

  const handleCropCompleted = async () => {
    if (typeof croppedAreaPixels === 'undefined') return
    const blob = await getCroppedBlob(src, croppedAreaPixels, rotation)
    onCompleteCrop(blob)
    resetCrop()
  }

  const isDisabledCropCompleted = (): boolean => {
    if (typeof dimensions === 'undefined') return true
    if (typeof croppedAreaPixels === 'undefined') return true
    if (croppedAreaPixels.width > dimensions.width) return true
    if (croppedAreaPixels.height > dimensions.height) return true
    return false
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="BodyModalCrop">
      <Modal.Header closeButton={true}>
        <Modal.Title>{t('upload.cropImage.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className={classNames('lc-crop', { 'lc-crop--hide': !isRotating })}
        >
          <div className="lc-crop__container">
            <Cropper
              cropSize={dimensions}
              image={src}
              crop={crop}
              zoom={scale}
              rotation={rotation}
              restrictPosition={false}
              minZoom={MIN_CROP_SCALE}
              onCropChange={setCrop}
              onZoomChange={setScale}
              onRotationChange={setRotation}
              onCropComplete={handleCropComplete}
              onCropAreaChange={(_area, areaPixels) =>
                setCrooppedAreaPixels(areaPixels)
              }
              aspect={aspectRatio}
            />
          </div>
          <div className="lc-crop__actions mt-2">
            <div>
              <button
                className={classNames('btn', {
                  'lc-crop__actions--active': isRotating,
                })}
                type="button"
                onClick={handleToogleIsRotating}
              >
                <Icon name="rotate" />
              </button>
              <button
                className={classNames('btn', {
                  'lc-crop__actions--active': isScaling,
                })}
                type="button"
                onClick={handleToogleIsScaling}
              >
                <Icon name="zoom_in" />
              </button>
            </div>
            {isScaling && (
              <div className="lc-crop__slider">
                <input
                  type="range"
                  min={MIN_CROP_SCALE}
                  max={MAX_CROP_SCALE}
                  step="any"
                  value={scale}
                  onChange={(evt) => setScale(evt.target.valueAsNumber)}
                />
              </div>
            )}
            {isRotating && (
              <div className="lc-crop__slider">
                <input
                  type="range"
                  min={MIN_CROP_ROTATION}
                  max={MAX_CROP_ROTATION}
                  step="any"
                  value={rotation}
                  onChange={(evt) => setRotation(evt.target.valueAsNumber)}
                />
              </div>
            )}
          </div>
        </div>
        {isDisabledCropCompleted() && (
          <div className="validation-errors">
            {t('upload.cropImage.validations.size')}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={handleClose}
          className="btn btn-outline-primary"
        >
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          onClick={handleCropCompleted}
          disabled={isDisabledCropCompleted()}
        >
          {t('common.save')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

const CropImage = memo(InternalCropImage) as typeof InternalCropImage

export default CropImage
