import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { User } from 'oidc-client'

import type { RootState } from '../reduxProvider'
import { AuthState } from './types'

const initialState: AuthState = {
  user: null,
  loaded: false,
  isLoggedIn: true,
}

export const login = createAsyncThunk('auth/login', async () => {
  const response: boolean = await new Promise((resolve) =>
    setTimeout(() => resolve(true), 1000)
  )
  return response
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload ? action.payload : null
      state.loaded = true
      state.isLoggedIn = true
    },
    unsetUser: (state) => {
      state.user = null
      state.loaded = false
      state.isLoggedIn = false
    },
  },
})

export const { setUser, unsetUser } = authSlice.actions

export const selectAuth = (state: RootState) => state.auth

export default authSlice.reducer
