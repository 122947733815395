import { Activity } from '../../core/activities/types'
import { BadgeMember, MemberChannel } from '../../core/member/types'

export type CarouselProps = {
  elements: Activity[] | BadgeMember[] | MemberChannel[]
  type: TypeCard
  onClick?: (id: string) => void
}

export enum TypeCard {
  Activity = 'Activity',
  Badges = 'Badges',
  Channels = 'Channels',
}
