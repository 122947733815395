import { Slide, toast, ToastOptions } from 'react-toastify'

import { SnackbarType, SnackbarOptions, useNotificationsResult } from './types'
import { SnackbarContent } from '../../containers/Snackbar'

const snackBarConfig: ToastOptions = {
  hideProgressBar: true,
  position: 'top-center',
  autoClose: 5000,
  closeOnClick: true,
  closeButton: true,
  draggable: false,
  transition: Slide,
}

const useNotifications = (): useNotificationsResult => {
  const error = (message: string): void => {
    send(message, SnackbarOptions.error)
  }

  const success = (message: string): void => {
    send(message, SnackbarOptions.success)
  }

  const warning = (message: string): void => {
    send(message, SnackbarOptions.warning)
  }

  const send = (message: string, type: SnackbarType) => {
    toast(<SnackbarContent type={type} message={message} />, {
      ...snackBarConfig,
      type,
    })
  }

  return {
    error,
    success,
    warning,
  }
}

export default useNotifications
