import classNames from 'classnames'
import SVG from 'react-inlinesvg'

import { CustomIconName } from './types'

interface IconProps {
  name: CustomIconName
  className?: string
}

const CustomIcon = ({ name, className }: IconProps) => {
  const myIcon = require(`./icons/${name}.svg`)

  return (
    <SVG src={myIcon} className={classNames('icon icon--custom', className)} />
  )
}

export default CustomIcon
