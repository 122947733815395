import { FC, ReactElement } from 'react'

import './styles.scss'

interface FormActionsProps {
  languageSelector?: ReactElement
}

const FormActions: FC<FormActionsProps> = ({ languageSelector, children }) => {
  return (
    <div className="lc-sticky-bar">
      <div className="lc-sticky-bar__language-selector">{languageSelector}</div>
      <div className="lc-sticky-bar__actions">{children}</div>
    </div>
  )
}

export default FormActions
