import { useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'

import useAuth from '../../../core/auth/useAuth'

const SignedOut: FC = () => {
  const navigate = useNavigate()
  const { getPostLogoutUri } = useAuth()

  useEffect(() => {
    async function handleRedirect() {
      const redirectPath = getPostLogoutUri()
      if (redirectPath && redirectPath.split('/')[1] === 't') {
        location.pathname = redirectPath ?? '/'
      } else {
        navigate({ pathname: redirectPath ?? '/' })
      }
    }

    localStorage.clear()
    handleRedirect()
  }, [getPostLogoutUri, navigate])

  return <></>
}

export default SignedOut
